<template>
  <div>
    <!--Title Bar-->
    <title-bar :title-value="'Academic Years'" />

    <!-- Filter -->
    <v-toolbar flat dense>
      <v-menu v-model="filterMenu" offset-y right :close-on-content-click="false">
        <template #activator="{ on }">
          <v-btn rounded color="primary" v-on="on"
            ><v-icon small left>fal fa-filter</v-icon><span v-if="showFiltered()">Filtered</span
            ><span v-else>Filter</span></v-btn
          >
        </template>

        <v-card width="400px">
          <v-card-text>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" sm="12" v-if="showFiltered()">
                  <v-btn block rounded color="primary" @click="clearFilter()">
                    <v-icon small left>fal fa-times</v-icon>Clear Filters
                  </v-btn>
                </v-col>

                <!--Year-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="d_year"
                    label="Year"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn block rounded color="primary" @click="filter()">
              <v-icon small left>fal fa-check</v-icon>Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-spacer />

      <!--Entry Dialog-->
      <year-entry />
    </v-toolbar>

    <!--Pagination-->
    <pagination
      :show="yearsStore.years.data && yearsStore.years.data.length > 0"
      :showTotal="true"
      :currentPage="yearsStore.years.current_page"
      :lastPage="yearsStore.years.last_page"
      :total="yearsStore.years.total"
      v-on:paginate-event="filter($event)"
    />

    <div v-if="progressStore.progress !== true">
      <!--List-->
      <list-basic
        :show="yearsStore.years.data && yearsStore.years.data.length > 0"
        :list-data="yearsStore.years.data"
        :title="'Years'"
        :section="36"
        @open-entry="openEntry($event)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TitleBar from "@/components/TitleBar";
import YearEntry from "@/components/YearEntry";
import Pagination from "@/components/Pagination";
import ListBasic from "@/components/ListBasic";

const { mapFields } = createHelpers({
  getterType: "yearsStore/getFilterField",
  mutationType: "yearsStore/updateFilterField"
});

export default {
  name: "Years",
  mixins: [mixin],
  components: {
    TitleBar,
    YearEntry,
    Pagination,
    ListBasic
  },
  computed: {
    ...mapState({
      yearsStore: state => state.yearsStore,
      progressStore: state => state.progressStore
    }),
    ...mapFields(["page", "d_year"])
  },
  data() {
    return {
      filterMenu: false
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.$store.dispatch("progressStore/set", true);
      /* Grab filters */
      const filters = this.$store.getters["yearsStore/getFilters"];
      /* Records based on filters */
      await this.$store.dispatch("yearsStore/fetch", filters);
      this.$store.dispatch("progressStore/set", false);
    },

    async filter(page) {
      this.filterMenu = false;
      this.page = page;
      this.initialize();
    },

    clearFilterColumns() {
      this.d_year = "";
      this.page = 1;
    },

    async clearFilter() {
      this.filterMenu = false;
      this.clearFilterColumns();
      this.initialize();
    },

    showFiltered() {
      if (this.d_year) {
        return true;
      }
      return false;
    },

    async openEntry(id) {
      await this.$store.dispatch("yearsStore/entry", id);
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
