<template>
  <div>
    <v-tooltip top color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="newEntry()">
          <v-icon color="primary">
            fal fa-plus-circle
          </v-icon>
        </v-btn>
      </template>
      <span>Add Academic Year</span>
    </v-tooltip>

    <!--Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="600">
      <v-card flat class="white">
        <v-card-title class="text-h5 secondary--text"
          >Academic Year Details<v-spacer /><v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" lazy-validation>
            <v-container fluid>
              <v-row dense>
                <!--Year-->
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="d_year"
                    label="Year Period"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Year Start-->
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="d_yearStart"
                    label="Year Start"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Kindergarten Eligible Date-->
                <v-col cols="12" sm="12" md="6">
                  <v-menu
                    :close-on-content-click="false"
                    v-model="menu1"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-on="on"
                        v-model="d_dateKindergartenEligible"
                        label="Kindergarten Eligible Date"
                        placeholder=" "
                        persistent-placeholder
                        prepend-icon="fal fa-calendar-alt"
                        required
                        @blur="date1 = $_parseDate(d_dateKindergartenEligible)"
                      />
                    </template>
                    <v-date-picker
                      v-model="date1"
                      @input="menu1 = false"
                      color="primary"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row dense>
                <!--Academic Start Date-->
                <v-col cols="12" sm="12" md="6">
                  <v-menu
                    :close-on-content-click="false"
                    v-model="menu2"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-on="on"
                        v-model="d_dateAcademicStart"
                        label="Academic Start Date"
                        placeholder=" "
                        persistent-placeholder
                        prepend-icon="fal fa-calendar-alt"
                        required
                        @blur="date2 = $_parseDate(d_dateAcademicStart)"
                      />
                    </template>
                    <v-date-picker
                      v-model="date2"
                      @input="menu2 = false"
                      color="primary"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <!--Academic End Date-->
                <v-col cols="12" sm="12" md="6">
                  <v-menu
                    :close-on-content-click="false"
                    v-model="menu3"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-on="on"
                        v-model="d_dateAcademicEnd"
                        label="Academic End Date"
                        placeholder=" "
                        persistent-placeholder
                        prepend-icon="fal fa-calendar-alt"
                        required
                        @blur="date3 = $_parseDate(d_dateAcademicEnd)"
                      />
                    </template>
                    <v-date-picker
                      v-model="date3"
                      @input="menu3 = false"
                      color="primary"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row dense>
                <!--Status-->
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    :items="valueListsStore.activeStatus"
                    label="Status"
                    placeholder=" "
                    persistent-placeholder
                    item-text="value"
                    item-value="id"
                    v-model="f_status"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers, mapMultiRowFields } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";

const { mapFields } = createHelpers({
  getterType: "yearsStore/getEntryField",
  mutationType: "yearsStore/updateEntryField"
});

export default {
  name: "YearEntry",
  mixins: [mixin],
  computed: {
    ...mapState({
      yearsStore: state => state.yearsStore,
      valueListsStore: state => state.valueListsStore
    }),
    ...mapFields([
      "show",
      "valid",
      "mode",
      "id",
      "d_year",
      "d_yearStart",
      "d_dateKindergartenEligible",
      "d_dateAcademicStart",
      "d_dateAcademicEnd",
      "f_status",
      "f_show",
      "date1",
      "date2",
      "date3"
    ])
  },
  watch: {
    date1() {
      this.d_dateKindergartenEligible = this.$_formatDate(this.date1);
    },
    date2() {
      this.d_dateAcademicStart = this.$_formatDate(this.date2);
    },
    date3() {
      this.d_dateAcademicEnd = this.$_formatDate(this.date3);
    }
  },
  data() {
    return {
      menu1: "",
      menu2: "",
      menu3: ""
    };
  },
  methods: {
    resetEntry() {
      this.$store.dispatch("yearsStore/resetEntry");
    },

    async newEntry() {
      this.resetEntry();

      this.mode = 1;
      this.show = true;
    },

    cancelEntry() {
      this.show = false;
      this.resetEntry();
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters["yearsStore/getEntry"];
        /* Create */
        if (this.mode === 1) {
          await this.$store.dispatch("yearsStore/create", entry);
        } else {
        /* Edit */
          await this.$store.dispatch("yearsStore/update", entry);
        }
        this.cancelEntry();
      }
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
